<template>
  <v-row no-gutters align="center" justify="center" class="my-5">
    <v-col cols="2" align="center" v-for="(language, i) in getLanguages" :key="i">
      <v-btn v-bind:color="getCurrentLanguage === language ? 'primary' : 'grey lighten-2'" class="btnDim"
        height="60px" @click="changeLanguage(language)"> {{ pullLanguage(language) }} </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "languageBar",
  data() {
    return {
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Spanish', code: 'es-US' },
        { name: 'Mandarin', code: 'zh' },
        { name: 'Assyrian', code: 'af-NA' },
        { name: 'Arabic', code: 'ar' },
        { name: 'French', code: 'fr' },
        { name: 'Vietnamese', code: 'vi' },
        { name: 'Punjabi', code: 'pa-Guru-IN' },
        { name: 'German', code: 'gr' }
      ]
    }
  },
  computed: {
    ...mapGetters(["getTouchCount", "getCurrentLanguage", "getLanguages"]),
  },
  methods: {
    pullLanguage(code) {
      return this.languages.filter(lang => lang.code === code)[0].name
    },
    changeLanguage(language) {
      console.log('Selected Language: ', language)
      switch (language) {
        case "en":
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "en";
          this.$store.commit("setCurrentLanguage", "en");
          this.$store.dispatch("identifyAvatarLangCodes", "en");
          this.$store.commit("setSpeechRecognitionLanguage", "en-US")
          this.$store.commit("setSearchBar", true)
          this.$store.dispatch("avatarSpeak", "I am now speaking in english");
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to English",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "es-US":
          this.$i18n.locale = "es";
          this.$store.commit("setCurrentLanguage", "es-US");
          this.$store.dispatch("identifyAvatarLangCodes", "es-US");
          this.$store.commit("setSpeechRecognitionLanguage", "es-US")
          this.$store.dispatch('avatarSpeak', 'Ahora estoy hablando en español');
          this.$store.commit("setSearchBar", true)
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Spanish",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "zh":
          this.$i18n.locale = "md";
          this.$store.commit("setCurrentLanguage", "zh");
          this.$store.dispatch("identifyAvatarLangCodes", "zh");
          this.$store.commit("setSpeechRecognitionLanguage", "zh-cn")
          this.$store.dispatch('avatarSpeak', '我现在说中文');
          this.$store.commit("setSearchBar", false)
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Mandarin",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "pa-Guru-IN":
          this.$i18n.locale = "pb"
          this.$store.commit("setCurrentLanguage", "pa-Guru-IN")
          this.$store.commit('setTextBubbleText', 'ਮੈਂ ਹੁਣ ਪੰਜਾਬੀ ਵਿੱਚ ਬੋਲ ਰਿਹਾ ਹਾਂ')
          this.$store.commit("setSearchBar", false)
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1)
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Punjabi",
            timeStamp: new Date(),
            requestType: "Touch",
          })
          break;
        case "af-NA":
          this.$i18n.locale = "as";
          this.$store.commit("setCurrentLanguage", "af-NA");
          this.$store.commit('setTextBubbleText', 'ܠܘܢܝܐ ܠܠܘܐ ܕܐܫܘܪܝܐ ܕܪܘܚܐ')
          this.$store.commit("setSearchBar", false)
          // Track the touch count and action performed.
          this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Assyrian",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.btnDim {
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>