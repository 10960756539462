<template>
    <v-row justify="center" align="start" no-gutters>
        <v-col class="py-8" cols="12" align="center">
            <span class="text-h2 font-weight-bold grey--text text--darken-3">
                {{ $t('default') }}
            </span>
        </v-col>
        <v-col cols="9" class="py-2">
            <v-row no-gutters class="fill-height">
                <v-col align="center" cols="6" v-for="page in $t('homepages')" :key="page.displayPosition">
                    <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center my-5"
                        elevation="10">
                        <v-img :src="page.displayIcon.url" width="60%" contain>
                        </v-img>
                        <!-- <v-icon size="160" color="secondary"> {{ page.displayIcon }} </v-icon> -->
                        <v-card-text class="text-h4 primary--text font-weight-bold">
                            {{ page.pageName }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-divider></v-divider>
            <languageBar></languageBar>
        </v-col>
    </v-row>
</template>

<script>
import languageBar from '@/components/languageBar'
import { mapGetters } from 'vuex'

export default {
    name: 'menuMatrix',
    components: {
        languageBar
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['getCMSlink', 'getTouchCount', 'isUserLooking', 'getHomepage'])
    },
    methods: {
        goto(page) {
            console.log('AT goto: ', page)
            if (page.pageType.length !== 0) {
                switch (page.pageType[0].__typename) {
                    case 'ComponentHomepageNavigation':
                        console.log('Navigate to: ', page)
                        this.$router.push('/' + page.pageType[0].pathName.toLowerCase());
                        this.$store.commit('setPageHeader', page.pageType[0].pageHeader)
                        this.$store.commit('setQnaKeyword', '')

                        // To start session using touch - when no face detected
                        if (!this.isUserLooking) {
                            this.$store.commit('setTouchCount', this.getTouchCount + 1)
                            if (this.getTouchCount === 1) {
                                // start time
                                this.$store.commit('setStartUserSession', new Date())

                                //session start object
                                this.$store.commit('setTouchRequest', {
                                    module: 'Default',
                                    action: 'Touch Detected',
                                    response: 'User Session Started - Touch Detected',
                                    timeStamp: new Date(),
                                    requestType: 'Session'
                                })
                            }
                        }
                        // Will be called irrespective of Touch Count
                        this.$store.commit('setTouchRequest', {
                            module: 'Home Page',
                            action: 'navigate',
                            response: page.pageType[0].pathName,
                            timeStamp: new Date(),
                            requestType: 'Touch'
                        })
                        break;
                    case 'ComponentHomepageDialogBox':
                    this.$store.commit('setTouchCount', this.getTouchCount + 1)
                        console.log('AT open Dialog box: ', page.pageType[0])
                        this.$store.commit('setTouchRequest', {
                            module: 'Home Page',
                            action: 'navigate',
                            response: 'todayshearing',
                            timeStamp: new Date(),
                            requestType: 'Touch'
                        })
                        window.open(page.pageType[0].textList, '_blank')
                        break;
                    default:
                        break;
                }
            }
            this.$store.commit('setDefaultBubbleText', true)
            this.$store.dispatch('avatarSpeak', page.speech)
        },
    },
    mounted() {
        console.log('Homepage Format: ', this.getHomepage)
    }
}
</script>

<style>
.menuItem {
    border: solid #2c6b87 !important;
    width: 310px;
    height: 280px;
}
</style>

<i18n>
{
  "en": {
      "default": "How Can I Help You?"
    },
  "es":{
      "default": "¿Le puedo ayudar en algo?"
    },
  "md":{
      "default": "我怎么帮你？"
    },
  "pb":{
      "default": "ਮੈਂ ਕਿਵੇਂ ਮਦਦ ਕਰ ਸਕਦਾ ਹਾਂ?"
    },
  "as":{
      "default": "ܥܕܬܐ ܐܢܬܘܢ ܡܢܢܐܢܢ ܒܣܘܩܢܐܝܬܐ؟"
    } 
}
</i18n>
