import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

// The getters are to be called in the way below only when Map Datastructure is used.
let getHomepageEn = () => {
  return store.getters.getHomepage.filter(page => page.locale === "en")
}

let getHomepageEs = () => {
  return store.getters.getHomepage.filter(page => page.locale === "es-US")
}

let getHomepageMd = () => {
  return store.getters.getHomepage.filter(page => page.locale === "zh")
}

let getHomepagePb = () => {
  return store.getters.getHomepage.filter(page => page.locale === "pa-Guru-IN")
}

let getHomepageAs = () => {
  return store.getters.getHomepage.filter(page => page.locale === "af-NA")
}


// Getters for Faq Category -> English Language
let getFaqCategoriesEn = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "en")
}

let getFaqCategoriesEs = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "es-US")
}

let getFaqCategoriesMd = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "zh")
}

let getFaqCategoriesPb = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "pa-Guru-IN")
}

let getFaqCategoriesAs = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "af-NA")
}

// Faqs
let getFaqsEn = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "en")
}

let getFaqsEs = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "es-US")
}

let getFaqsMd = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "zh")
}

let getFaqsPb = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "pa-Guru-IN")
}

let getFaqsAs = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "af-NA")
}

// Floors
let getFloorsEn = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "en")
}

let getFloorsEs = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "es-US")
}

let getFloorsMd = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "zh")
}

let getFloorsPb = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "pa-Guru-IN")
}

let getFloorsAs = () => {
  return store.getters.getFloors.filter(floor => floor.locale === "af-NA")
}

// Getters for Maps -> English Language
let getMapsEn = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "en") {
      temp.push(map)
    }
  })
  return temp;
}

let getMapsEs = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "es-US") {
      temp.push(map)
    }
  })
  return temp;
}

let getMapsMd = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "zh") {
      temp.push(map)
    }
  })
  return temp;
}

let getMapsPb = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "pa-Guru-IN") {
      temp.push(map)
    }
  })
  return temp;
}

let getMapsAs = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === "af-NA") {
      temp.push(map)
    }
  })
  return temp;
}

// Form Categories
let getFormCategoriesEn = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "en")
}

let getFormCategoriesEs = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "es-US")
}

let getFormCategoriesMd = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "zh")
}

let getFormCategoriesPb = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "pa-Guru-IN")
}

let getFormCategoriesAs = () => {
  return store.getters.getFormCategories.filter(category => category.locale === "af-NA")
}

//Forms
let getFormsEn = () => {
  return store.getters.getForms.filter(form => form.locale === "en")
}

// QNA Endpoint
let getQnaEn = () => {
  return store.getters.getQnaEndpointEN
}
let getQnaEs = () => {
  return store.getters.getQnaEndpointES
}
let getQnaMd = () => {
  return store.getters.getQnaEndpointMD
}
let getQnaPb = () => {
  return store.getters.getQnaEndpointPB
}
let getQnaAs = () => {
  return store.getters.getQnaEndpointAS
}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      // Homepage
      homepages: getHomepageEn,

      // Faqs
      faqs: getFaqsEn,
      faqCategory: getFaqCategoriesEn,

      // Maps
      maps: getMapsEn,
      floors: getFloorsEn,

      // Forms
      formCategory: getFormCategoriesEn,
      forms: getFormsEn,

      //QnA Endpoint
      qnaEndpoint: getQnaEn,

      kbError: "Sorry I'm still learning. Please ask me a different question or, get information about this topic at the Self Help Office located here.",
      defaultKBError: "Sorry, I'm not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Self Help Office located here.",
      mapNotFound: "Sorry, I can't find the requested location, please go to the Self Help Office for more information.",
      formNotFound: "Sorry, I can't find the requested form, please go to the Self Help Office for more information.",
      selectOptionMsg: "Tap on the below, or click on the microphone to ask another question.",
      isThinking: "Thinking..."
    },
    es: {
      //Homepage
      homepages: getHomepageEs,
      // Faqs
      faqs: getFaqsEs,
      faqCategory: getFaqCategoriesEs,

      // Maps
      maps: getMapsEs,
      floors: getFloorsEs,

      // Forms
      formCategory: getFormCategoriesEs,
      forms: getFormsEn,

      //QnA Endpoint
      qnaEndpoint: getQnaEs,

      kbError: "Lo siento, todavía estoy aprendiendo. Hágame una pregunta diferente u obtenga información sobre este tema en la Oficina de autoayuda que se encuentra aquí.",
      defaultKBError: "Lo siento, no estoy seguro de cómo responder a esta pregunta. Hágame otra pregunta o puede continuar usando la interfaz táctil. Además, puede obtener más información en la Oficina de Autoayuda que se encuentra aquí.",
      mapNotFound: "Lo siento, no puedo encontrar la ubicación solicitada, vaya a la Oficina de autoayuda para obtener más información.",
      formNotFound: "Lo siento, no puedo encontrar el formulario solicitado, diríjase a la Oficina de autoayuda para obtener más información.",
      selectOptionMsg: "Toque a continuación o haga clic en el micrófono para hacer otra pregunta.",
      isThinking: "Pensando..."
    },
    md: {
      //Homepage
      homepages: getHomepageMd,
      // Faqs
      faqs: getFaqsMd,
      faqCategory: getFaqCategoriesMd,

      // Maps
      maps: getMapsMd,
      floors: getFloorsMd,

      // Forms
      formCategory: getFormCategoriesMd,
      forms: getFormsEn,

      //QnA Endpoint
      qnaEndpoint: getQnaMd,

      kbError: '抱歉我还在学习。请问我其他问题，或者在此处的自助办公室获取有关此主题的信息。',
      defaultKBError: '抱歉，我不知道如何回答这个问题。请问我另一个问题，或者您可以继续使用触摸界面。此外，您还可以在此处的自助办公室获取更多信息。',
      mapNotFound: '抱歉，我找不到所需的位置，请前往自助办公室了解更多信息。',
      formNotFound: '抱歉，我找不到所需的表格，请前往自助办公室了解更多信息。',
      selectOptionMsg: "点击下面的按钮，或点击麦克风提出另一个问题。"
    },
    pb: {
      //Homepage
      homepages: getHomepagePb,
      // Faqs
      faqs: getFaqsPb,
      faqCategory: getFaqCategoriesPb,

      // Maps
      maps: getMapsPb,
      floors: getFloorsPb,

      // Forms
      formCategory: getFormCategoriesPb,
      forms: getFormsEn,

      //QnA Endpoint
      qnaEndpoint: getQnaPb
    },
    as: {
      //Homepage
      homepages: getHomepageAs,
      // Faqs
      faqs: getFaqsAs,
      faqCategory: getFaqCategoriesAs,

      // Maps
      maps: getMapsAs,
      floors: getFloorsAs,

      // Forms
      formCategory: getFormCategoriesAs,
      forms: getFormsEn,

      //QnA Endpoint
      qnaEndpoint: getQnaAs
    },
  }
})
