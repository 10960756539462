<template>
  <v-row no-gutters>
    <v-col cols="3" align-self="center">
      <v-card height="775" flat class="containerBorder mx-3">
        <v-card-title class="d-flex justify-center font-weight-bold primary--text">
          {{ $t('header') }}
        </v-card-title>
        <v-divider class="mx-3"></v-divider>
        <v-card class="overflow-y-auto" height="695" flat>
          <v-list text>
            <v-list-item-group>
              <v-list-item v-for="(item, i) in maps" :key="i" color="primary"
                @click="selectImage(item.mapImage, item.mapFloor, item.speech, item.mapName)">
                <v-list-item-content>
                  <v-card min-height="45" @click="selectedMap = item.mapName"
                    class="pa-0 ma-0 text-wrap white--text text-left"
                    v-bind:color="item.mapName === selectedMap ? '#d37444' : 'primary'">
                    <v-card-title class="text-subtitle-1 font-weight-bold">
                      {{
                        item.mapName.toUpperCase() | trimLength
                      }}
                    </v-card-title>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-tabs background-color="primary" dark active-class="tabClass" v-model="active_tab" height="70" centered>
        <div v-for="(floor, i) in $t('floors')" :key="i">
          <v-tab v-if="showtabs" align="center" class="text-h6 pa-5" @click="
            selectImage(
              floor.floorImage.url,
              floor.floorNumber,
              floor.speech,
              floor.floorName
            )">
            {{ floor.floorName }}
          </v-tab>
        </div>
      </v-tabs>
      <v-row no-gutters>
        <v-col align="center" class="pt-7">
          <v-img :src="url" width="79%"> </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "maps",
  data() {
    return {
      active_tab: 1,
      url: "",
      floorNum: null,
      showList: false,
      count: 0,
      selectedMap: '',
      showtabs: true
    };
  },
  computed: {
    ...mapGetters(["getMaps", "getCMSlink"]),
    maps() {
      return this.$i18n.t('maps').sort((a, b) => a.displayPosition - b.displayPosition);
    }
  },
  methods: {
    filterMaps(category) {
      let temp = []
      this.$i18n.t("maps").forEach(map => {
        if (map.category === category) {
          temp.push(map)
        }
      })
      return temp
    },
    selectImage(mapPath, mapFloor, speech, mapName) {
      console.log('Map Floor: ', mapPath, mapFloor)
      this.active_tab = mapFloor;
      this.url = mapPath;
      if (mapFloor === "4" || mapFloor === 4) {
        this.showtabs = false
      } else {
        this.showtabs = true
      }
      // Condition added to avoid redundant call from Mounted
      if (speech !== undefined) {
        this.$store.dispatch("avatarSpeak", speech);
        // Touch Usage
        this.$store.commit("setTouchRequest", {
          module: "Maps and Directory",
          action: "View Map",
          response: mapName,
          timeStamp: new Date(),
          requestType: "Touch",
        });
      }
    },
    showMaps(num) {
      if (this.floorNum === num) {
        this.showList = false;
        this.floorNum = 0;
      } else {
        this.floorNum = num;
        this.showList = true;
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 40) {
        return val;
      }
      return `${val.substring(0, 35)}...`;
    },
  },
  mounted() {
    this.$i18n.t("floors").forEach(floor => {
      if (floor.defaultLocation) {
        this.selectImage(floor.floorImage.url, floor.floorNumber)
      }
    })
  },
};
</script>
<i18n>
{
  "en": {
      "header": "MAP DIRECTORY"
    },
  "es":{
      "header": "Directorio Mapas"
    },
  "md" :{
        "header": "地图目录"
    },
  "pb" :{
        "header": "ਮੈਪ ਡਾਇਰੈਕਟਰੀ"
    },
  "as" :{
        "header": "ܕܐܬܘܪܬܐ ܕܟܐܦܐ"
    }
}
</i18n>
<style scoped>
.containerBorder2 {
  border-radius: 0px !important;
}
</style>