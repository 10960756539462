import gql from 'graphql-tag'

// ---------------------------- Homepages --------------------------------------------

export const homepageQuery = gql`
query homeQuery($alias : JSON, $location : String, $lang: String) {
  homepages (sort: "displayPosition:asc", where : { alias : $alias, kioskLocationCode_contains : $location }, locale: $lang) {
    id
    speech    
    pageName
    locale
    alias     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        textList      
      }
    }
    displayIcon{
      id
      url
    }
  }
}
`

// ------------------------------ Faqs ----------------------------------------------

export const faqQuery = gql`
query faqQuery($lang: String) {
  faqs (start: 0, locale: $lang) {
    id
    question
    answer
    category
    showMap
    mapName
    showform
    formName
    showQrcode
    qrDetails
    locale
    keyword
    LinkID
  }
}
`

export const faqQuery2 = gql`
query faqQuery($lang: String) {
  faqs (start: 100, locale: $lang) {
    id
    question
    answer
    category
    showMap
    mapName
    showform
    formName
    showQrcode
    qrDetails
    locale
    keyword
    LinkID
  }
}
`


export const faqCategoryQuery = gql`
query faqCategoryQuery($lang: String) {
  faqCategories(sort: "displayOrder:asc", locale: $lang) {
    id
    categoryName
    displayType
    displayOrder
    locale
  }
}
`

// ------------------------------ Maps ----------------------------------------------
export const mapQuery = gql`
query mapQuery($location : String, $lang: String) {
  maps (sort: "displayPosition:asc", where : { kioskLocation_contains : $location }, locale: $lang){
    id
    mapName
    mapFloor
    speech
    kioskLocation
    displayPosition
    locale
    mapImage {
      id
      url
    }
  }
}
`

export const floorQuery = gql`
query floorQuery($location : String, $lang: String) {
  floors (sort: "floorNumber:asc", where : { kioskLocation_contains : $location }, locale: $lang){ 
    id
    floorName
    floorNumber
    speech
    kioskLocation
    defaultLocation
    locale
    floorImage {
      id
      url
    }
  }
}
`

// ------------------------------ Forms ----------------------------------------------

export const formCategoryQuery = gql`
query formCategoryQuery($lang: String) {
  formCategories (sort: "id:asc", locale: $lang) {
    id
    name   
    locale  
    categoryType{
      ... on ComponentFormCategoryTypeCategoryType{
       	type 
      }
    }
    identifier
  }
}
`

export const formQuery = gql`
query formQuery($lang: String) {
  forms (start: 0, sort: "id:asc", locale: $lang) {
    id
    formName
    documentUrl
    document{
      url
    }
    documentType
    formCategory
    locale
  }
}
`

export const formQuery2 = gql`
query formQuery($lang: String) {
  forms (start: 100, sort: "id:asc", locale: $lang) {
    id
    formName
    documentUrl
    document{
      url
    }
    documentType
    formCategory
    locale
  }
}
`