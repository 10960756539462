<template>
    <v-dialog width="45%" persistent v-model="getQnaPair.show">
        <v-card>
            <v-card-title class="text-h5">{{ getQnaPair.question }}
                <v-spacer></v-spacer>
                <v-icon color="error" @click="$store.commit('closeQnaPair')">mdi-close</v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2 text-h6" v-html="getQnaPair.answer">
            </v-card-text>

        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'questionAnswerViewer',
    computed: {
        ...mapGetters(['getQnaPair'])
    }
}
</script>