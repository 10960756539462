<template>
  <v-row no-gutters class="mt-16 mr-10">
    <v-col cols="2"></v-col>
    <v-col cols="5" align="right" class="mt-16">
      <v-card height="350" width="90%" flat class="containerBorder1 mt-16">
        <v-card-title class="justify-center" style="background-color: #2c6b87; color: white;">{{ $t('scanStatement') }}</v-card-title>
        <v-card-title class="font-weight-bold justify-center"> {{ $t('availableType') }}: </v-card-title>
        <v-row no-gutters class="mt-2">
          <v-col cols="6" align="left">
            <v-card-text class="font-weight-bold text-h6">
              1) {{ $t('type1') }}
            </v-card-text>
          </v-col>
          <v-col cols="6" align="left">
            <v-card-text class="font-weight-bold text-h6">
              2) {{ $t('type2') }}
            </v-card-text>
          </v-col>
          <v-col cols="6" align="left">
            <v-card-text class="font-weight-bold text-h6">
              3) {{ $t('type3') }}
            </v-card-text>
          </v-col>
          <v-col cols="6" align="left">
            <v-card-text class="font-weight-bold text-h6">
              4) {{ $t('type4') }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="3" align="center" class="mt-16">
      <v-card height="350" width="80%" flat class="containerBorder1 mt-16">
        <qrcode-vue :value="value" :size="size" level="H" class="pt-6" />
      </v-card>
    </v-col>
    <v-col cols="2"></v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import QrcodeVue from 'qrcode.vue'
let config = require("./../../config/config");
const axios = require('axios');
import moment from "moment";
export default {
  name: "todayshearing",
  data() {
    return {
      caseNoSearch: "",
      nameSearch: {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        license: "",
        licenseState: "TX"
      },
      value: 'https://stanportal.stanct.org/search',
      size: 300,
    };
  },
  components: {
    SimpleKeyboard,
    QrcodeVue
  },
  methods: {
    searchByCaseNumber(caseNo) {
      let doc = document.implementation.createDocument(null, "Transaction", null);

      let txn = doc.documentElement;

      txn.setAttribute("ReferenceNumber", 'KB-001');
      txn.setAttribute("Source", "ODY-CTYINT:TEST");
      txn.setAttribute("TransactionType", "API-Find-Case");

      let message = doc.createElement("Message");
      message.setAttribute("MessageType", "FindCase");
      message.setAttribute("Source", "ODY-CTYINT:TEST");
      message.setAttribute("ReferenceNumber", 'KB-001');
      message.setAttribute("NodeID", '1');
      message.setAttribute("UserID", '1');

      let options = doc.createElement("Options");

      let nodes = doc.createElement("Nodes");
      let allNodes = doc.createElement("All");
      allNodes.textContent = "true";
      nodes.appendChild(allNodes);
      options.appendChild(nodes);

      let caseCategories = doc.createElement("CaseCategories");
      let allCategories = doc.createElement("All");
      allCategories.textContent = "true";
      caseCategories.appendChild(allCategories);
      options.appendChild(caseCategories);

      let caseTypes = doc.createElement("CaseTypes");
      let allTypes = doc.createElement("All");
      allTypes.textContent = "true";
      caseTypes.appendChild(allTypes);
      options.appendChild(caseTypes);

      message.appendChild(options);

      let searchCriteria = doc.createElement("SearchCriteria");
      let caseNode = doc.createElement("Case");
      let caseNumberNode = doc.createElement("CaseNumber");
      caseNumberNode.textContent = 'CR-21-000001';
      caseNode.appendChild(caseNumberNode);
      searchCriteria.appendChild(caseNode);
      message.appendChild(searchCriteria);

      txn.appendChild(message);

      let serializer = new XMLSerializer();
      let result = serializer.serializeToString(doc);

      console.log(result);
      // Call the Google Cloud Function here

      const config = {
        method: 'post',
        url: 'https://us-central1-stanislaus-bc0a1.cloudfunctions.net/apiV2/getcasebycaseno/a763cd056f1b2405788443b7197e0708',
        params: { xmlrequest: result }
      }
      axios(config)
        .then(result => {
          console.log('Result from axios call', result)
        })
        .catch(error => {
          console.log('Error : ', error.message)

        })

    }
  },
  mounted() {
    console.log('Coming in Todays Hearing');
  },
  filters: {
    formatStringDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LL");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
  },
};
</script>

<style></style>

<i18n>
{
  "en": {
      "categoryTitle": "CATEGORIES",
      "scanStatement": "Scan the QR Code using your phone camera to search for hearing details",
      "availableType": "Below are the available search types",
      "type1": "Search by Case Number",
      "type2": "Search by Name and DOB",
      "type3": "Business Search",
      "type4": "Filing Date Search"
    },
  "es":{
      "categoryTitle": "Categorías",
      "scanStatement": "Escanee el código QR con la cámara de su teléfono para buscar detalles de la audiencia",
      "availableType": "A continuación se muestran los tipos de búsqueda disponibles",
      "type1": "Buscar por número de caso",
      "type2": "Buscar por nombre y fecha de nacimiento",
      "type3": "Búsqueda de empresas",
      "type4": "Búsqueda de fecha de presentación"
    },
  "md":{
      "categoryTitle": "类别",
      "scanStatement": "使用手机摄像头扫描二维码搜索听证会详细信息",
      "availableType": "以下是可用的搜索类型",
      "type1": "按案件编号搜索",
      "type2": "按姓名和出生日期搜索",
      "type3": "商业搜索",
      "type4": "申请日期搜索"
    },
  "pb" :{
      "categoryTitle": "ਵਰਗ",
      "scanStatement": "ਸੁਣਨ ਦੇ ਵੇਰਵਿਆਂ ਦੀ ਖੋਜ ਕਰਨ ਲਈ ਆਪਣੇ ਫ਼ੋਨ ਕੈਮਰੇ ਦੀ ਵਰਤੋਂ ਕਰਕੇ QR ਕੋਡ ਨੂੰ ਸਕੈਨ ਕਰੋ",
      "availableType": "ਹੇਠਾਂ ਉਪਲਬਧ ਖੋਜ ਕਿਸਮਾਂ ਹਨ",
      "type1": "ਕੇਸ ਨੰਬਰ ਦੁਆਰਾ ਖੋਜ ਕਰੋ",
      "type2": "ਨਾਮ ਅਤੇ DOB ਦੁਆਰਾ ਖੋਜ ਕਰੋ",
      "type3": "ਵਪਾਰ ਖੋਜ",
      "type4": "ਫਾਈਲ ਕਰਨ ਦੀ ਮਿਤੀ ਖੋਜ"
    },
  "as" :{
      "categoryTitle": "ܟܘܪܕܐܢܐ",
      "scanStatement": "Scan the QR Code using your phone camera to search for hearing details",
      "availableType": "Below are the available search types",
      "type1": "Search by Case Number",
      "type2": "Search by Name and DOB",
      "type3": "Business Search",
      "type4": "Filing Date Search"
    } 
}
</i18n>
