import * as fb from '../../firebase/index.js'
import crypto from "crypto";
import axios from 'axios'

export default {
    state: {
        callEndTimer: 0,
        callListener: null
    },
    getters: {
        getCallListener: state => state.callListener
    },
    mutations: {

    },
    actions: {
        initiateCall({ getters, dispatch }) {
            if (getters.getKioskProfile.data().groups.length === 0) {
                dispatch('avatarSpeak', 'Telepresence not set up. Please contact the Law Library.')
            } else {
                dispatch('availableUsers', getters.getKioskProfile.data().groups)
            }
        },

        availableUsers({ dispatch }, groups) {
            let users = []
            fb.registeredUsersCollection.where('groups', 'array-contains-any', groups)
                .where('onlineStatus', '==', 'Available').where('onCall', '==', false)
                .get().then(user => {
                    user.docs.forEach(doc => {
                        users.push(doc.id)
                    })
                    if (users.length > 0) {
                        dispatch('startCall', users)
                    } else {
                        dispatch('avatarSpeak', 'No users are available. Please try after sometime.')
                    }

                })
        },

        startCall({ getters, state, dispatch }, availableUsers) {
            console.log('Available Users: ', availableUsers)
            let callObj = {
                callStatus: "Initiated",
                statusCode: 201,
                meetingCode: crypto.randomBytes(50).toString("hex").slice(0, 20),
                startTime: new Date(),
                endTime: null,
                conferenceAttendees: [],
                callerInfo: getters.getKioskProfile.data(),
                callInitiator: { name: getters.getKioskProfile.data().name, id: getters.getKioskProfile.id },
                callType: 'Pull',
                requestedUser: availableUsers,
                calledUser: availableUsers,
                availableUsers: availableUsers,
                source: true
            };

            fb.telepresenceCollection.add(callObj).then(response => {
                state.callEndTimer = setTimeout(() => {
                    fb.telepresenceCollection.doc(response.id).update({
                        callStatus: 'Not Answered',
                        statusCode: 102,
                        endTime: new Date(),
                        requestedUser: null
                    })
                    getters.getCallListener()
                    dispatch(
                        "avatarSpeak",
                        "Please try again after sometime."
                    );
                }, 35000)
                dispatch("handleCall", response.id)
                dispatch("declineCloudFunction", response.id)
            })
        },

        handleCall({ state, dispatch }, callId) {
            state.callListener = fb.telepresenceCollection.doc(callId).onSnapshot(
                call => {
                    switch (call.data().statusCode) {
                        case 201:
                            // Initiated
                            dispatch('avatarSpeak', 'Hang On! Connecting you to a live person.')
                            break;
                        case 100:
                            // In Progress
                            console.log('Call Answered: ', call)
                            dispatch('avatarSpeak', 'Video Call page coming up soon')
                            clearTimeout(state.callEndTimer)
                            break;
                        case 101:
                            // Declined
                            dispatch(
                                "avatarSpeak",
                                "All requested users declined the call. Please try again after sometime."
                            );
                            clearTimeout(state.callEndTimer)
                            break;
                        case 103:
                            // Ended
                            break;
                        default:
                            console.log('Something went wrong')
                    }
                }
            )
        },

        declineCloudFunction({ commit }, id) {
            const config = {
                method: 'post',
                url: 'https://us-central1-el-paso-5508e.cloudfunctions.net/apiV2/getCallInfo/a763cd056f1b2405788443b7197e0708',
                params: { call: id, action: 'get' }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        }
    }
}
