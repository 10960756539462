<template>
    <v-row align="center" justify="center" no-gutters>
        <v-col cols="9" align-self="center" class="my-5">
            <v-card width="200" align="center" @click="startCall()">
                <v-card-title class="justify-center">
                    <v-img contain src="@/assets/law-book.png" width="90%"></v-img>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-title class="justify-center">
                    LAW LIBRARY
                </v-card-title>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name: 'telepresence',
    computed: {
        ...mapGetters(['getKioskProfile', 'isUserLooking'])
    },
    methods: {
        startCall() {
            this.$store.dispatch('initiateCall')
        }
    }
}


</script>
