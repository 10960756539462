<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat v-if="getSearchBar">
          <v-card-text class="d-flex justify-center pa-0">
            <v-text-field placeholder="Enter a keyword to search for FAQ" class="text-h5" hide-details outlined
              height="55px" rounded v-model="searchKeyword" @click="expand = true; selectedFaq = []"
              :append-icon="searchBarIcon" clear-icon="mdi-close-circle" @click:append=" searchKeyword = '';">
            </v-text-field>
            <v-icon size="35" class="ml-2" v-if="expand"
              @click=" expand = !expand; searchKeyword = ''; searchId = null;">mdi-keyboard-off-outline</v-icon>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="3" class="mt-5" align="center" v-if="!expand && searchKeyword === '' && searchId === ''">
            <v-card width="95%" class="containerBorder" :height="cardHeight">
              <v-card-title class="justify-center primary--text">{{ $t('categoryTitle') }}</v-card-title>
              <v-divider class="mx-3"></v-divider>
              <v-list class="overflow-y-auto" :height="cardHeight - 75">
                <v-list-item v-for="(   categorName, i   ) in    faqCategory   " :key="i">
                  <v-list-item-content>
                    <v-btn height="45" @click="category = categorName"
                      v-bind:color="category === categorName ? 'secondary' : 'primary'">{{ categorName }}</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col :cols="colLenght" class="mt-5" align="center">
            <v-card width="95%" class="containerBorder overflow-y-auto" :height="cardHeight">
              <v-expansion-panels focusable dark class="px-5 py-3"
                v-if="!expand && searchKeyword === '' && searchId === ''" popout v-model="panel">
                <v-expansion-panel v-for="(  item, i  ) in   filteredFaqs(category)  " :key="i" class="mt-2"
                  :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click=" clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters>
                      <v-col cols="9" v-if="item.showMap || item.showForm || item.showQrCode">
                        <span v-html="item.answer"></span>
                      </v-col>
                      <v-col v-else cols="12">
                        <span v-html="item.answer"></span>
                      </v-col>
                      <v-col cols="3" align="center">
                        <v-btn v-if="item.showForm" class="my-3" color="secondary" tile width="85%"
                          @click="displayPdfDoc(item.formName)">{{ item.formName
                          }}</v-btn>

                        <v-btn v-if="item.showQrCode" color="secondary" tile class="my-3" width="85%"
                          @click=" sendLink(item.qrInfo.qrlist[0].link, item.qrInfo.qrlist[0].name)">
                          <v-icon left dark medium> mdi-link-variant </v-icon>
                          get website link</v-btn>

                        <div v-if="item.showMap">
                          <v-btn v-for="(  map, i  ) in   assignedMaps(item.mapName)  " :key="i" tile color="secondary"
                            width="85%" @click=" showMap(map)" class="my-3">
                            <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                            {{ map }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels focusable dark class="px-3" popout v-else v-model="panel">
                <v-expansion-panel v-for="(  item, i  ) in   matchingFaqs  " :key="i" class="mt-2" :value="item.question">
                  <v-expansion-panel-header color="primary" class="white--text text-h6" @click=" clickedFaq(item)">
                    <template v-slot:actions>
                      <v-icon color="white" large>mdi-arrow-down-bold-circle</v-icon>
                    </template>
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="white" class="black--text pt-4 text-justify text-h6">
                    <v-row class="fill-height" no-gutters>
                      <v-col cols="9" v-if="item.showMap || item.showForm || item.showQrCode">
                        <span v-html="item.answer"></span>
                      </v-col>
                      <v-col v-else cols="12">
                        <span v-html="item.answer"></span>
                      </v-col>
                      <v-col cols="3" align="center">
                        <v-btn v-if="item.showForm" class="my-3" color="secondary" tile width="85%"
                          @click="displayPdfDoc(item.formName)">{{ item.formName
                          }}</v-btn>

                        <v-btn v-if="item.showQrCode" color="secondary" tile class="my-3" width="85%"
                          @click=" sendLink(item.qrInfo.qrlist[0].link, item.qrInfo.qrlist[0].name)">
                          <v-icon left dark medium> mdi-link-variant </v-icon>
                          get website link</v-btn>

                        <div v-if="item.showMap">
                          <v-btn v-for="(  map, i  ) in   assignedMaps(item.mapName)  " :key="i" tile color="secondary"
                            width="85%" @click=" showMap(map)" class="my-3">
                            <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                            {{ map }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";
const closest_match = require("closest-match");
export default {
  name: "faqs",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 650,
      category: '',
      colLenght: '9',
      panel: 0,
      searchId: null
    };
  },
  computed: {
    ...mapGetters(["getQnaKeyword", "getFaqCategory", "getSearchBar"]),
    matchingFaqs() {
      let temp = [];
      let distance;
      if (this.searchKeyword !== "") {
        temp = this.$i18n.t("faqs").filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))
      } else {
        console.log('Coming in here: ', this.searchId)
        temp = this.$i18n.t("faqs").filter(faq => faq.linkId === this.searchId)
        console.log('Coming in here: ', temp)
      }

      if (temp.length > 0) {
        temp.forEach(ele => {
          ele['distance'] = 1
        })
      } else {
        temp = []
        if (this.searchKeyword !== "") {
          this.$i18n.t("faqs").forEach(faq => {
            let arrayTemp = faq.keyword.split(' ');
            arrayTemp.forEach(word => {
              if (this.searchKeyword.length > 1) {
                let keywordArray = this.searchKeyword.split(',')
                keywordArray.forEach(keyword => {
                  distance = closest_match.distance(word.toLowerCase(), keyword.toLowerCase())
                })
              } else {
                distance = closest_match.distance(word.toLowerCase(), this.searchKeyword.toLowerCase())
              }

              if (distance <= 3) {
                faq['distance'] = distance
                temp.push(faq)
              }
            })
          })
        }
        else {
          if (this.searchId !== null) {
            this.$i18n
              .t("faqs").forEach(faq => {
                faq['distance'] = 0
                temp.push(faq)
              })
          }
        }
      }
      return temp.sort((a, b) => (a.distance >= b.distance) ? 1 : -1)

    },
    faqCategory() {
      return this.$i18n
        .t("faqCategory").map(category => category.categoryName)
    },
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        this.cardHeight = 400;
        this.colLenght = 12
      } else {
        if (this.searchKeyword !== "") {
          this.searchBarIcon = "mdi-close-circle";
        } else {
          this.searchBarIcon = "mdi-magnify";
        }
        this.cardHeight = 650;
        this.colLenght = 9
      }
    },
    getQnaKeyword(newVal) {
      console.log('New Val at QNA keyword: ', newVal)
      this.searchId = newVal
      this.colLenght = 12
    },
    searchKeyword(newVal) {
      if (newVal === "") {
        this.searchBarIcon = "mdi-magnify";
      }
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    filteredFaqs(category) {
      console.log('Selected Category: ', category)
      return this.$i18n
        .t("faqs")
        .filter((faq) =>
          faq.category.toLowerCase().includes(category.toLowerCase())
        );
    },
    assignedMaps(maps) {
      let assignedMap = maps.split(",")
      return assignedMap
    },
    showMap(map) {
      this.$i18n.t("maps").forEach(mapper => {
        if (mapper.mapName.toLowerCase().trim() === map.toLowerCase().trim()) {
          this.$store.dispatch("openImageViewer", {
            name: mapper.mapName,
            url: mapper.mapImage,
          });
          this.$store.dispatch("avatarSpeak", mapper.speech);
        }
      })
    },
    sendLink(url, name) {
      this.$store.dispatch("avatarSpeak", "Please enter your Email ID or scan the QR Code to get this link.");
      this.$store.dispatch('openEmailViewer', {
        pdfType: 'link',
        pdfName: name,
        pdfUrl: url
      })
      //}
    },
    displayPdfDoc(formName) {
      let formInfo = this.$i18n.t("forms").filter(form => form.formName === formName)
      console.log('Form Info: ', formInfo)
      this.$store.dispatch('openPdfViewer', {
        url: formInfo[0].document,
        name: formInfo[0].formName,
        emailLink: formInfo[0].documentUrl,
        type: formInfo[0].documentType
      })
    },
    // Touch Usage
    clickedFaq(faq) {
      this.category = faq.category
      this.$store.commit("setTouchRequest", {
        module: "Frequently Asked Question",
        action: "Show Faq",
        response: faq.question,
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 70)}...`;
    },
  },
  mounted() {
    // Search Faq by keyword
    this.category = this.$i18n.t("faqCategory")[0].categoryName
    this.searchId = this.getQnaKeyword
    console.log('Search ID at mounted: ', this.searchId)
    if (this.searchKeyword !== '' || this.searchId !== '') {
      this.colLenght = 12
    }
  },
};
</script>

<style>
.tabClass {
  background-color: #d37444 !important;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 0px !important;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #2c6b87 !important;
  overflow-y: scroll;
  border-radius: 15px !important;
}

.linkCardBorder {
  border-color: #2c6b87 !important;
  border-bottom: solid;
  color: white !important;
  border-radius: 0px !important;
  margin-bottom: 2px;
  border-width: 0.5px;
}
</style>
<i18n>
  {
    "en": {
        "placeHolder": "Enter a keyword to search for FAQ",
        "categoryTitle": "CATEGORIES"
      },
    "es":{
        "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes",
        "categoryTitle": "Categorías"
      },
    "md" :{
        "categoryTitle": "类别"
      },
    "pb" :{
        "categoryTitle": "ਵਰਗ"
      },
    "as" :{
        "categoryTitle": "ܟܘܪܕܐܢܐ"
      }
  }
</i18n>