<template>
    <v-dialog width="25%" v-model="getQrcodeViewer.show">
        
    </v-dialog>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'qrcodeViewer',
    components: {
        QrcodeVue
    },
    computed: {
        ...mapGetters(['getQrcodeViewer'])
    }
}
</script>