import axios from "axios";
let config = require("./../../../config/config");
import router from "@/router";
import i18n from "../../i18n";
export default {
  state: {
    qnaStatus: null,
    qnaKeyword: "",
    wrongAnswerCount: 0,
    court: "",
    faqCategory: "",
    isThinking: false,
    requestQna: true,
    qnaPair: {},
  },
  getters: {
    getQnaStatus: (state) => state.qnaStatus,
    getQnaKeyword: (state) => state.qnaKeyword,
    getWrongAnsCount: (state) => state.wrongAnswerCount,
    getCourt: (state) => state.court,
    getFaqCategory: (state) => state.faqCategory,
    getIsThinking: (state) => state.isThinking,
    getRequestQna: (state) => state.requestQna,
    getQnaPair: (state) => state.qnaPair,
  },
  mutations: {
    setQnaStatus(state, status) {
      state.qnaStatus = status;
    },
    setQnaKeyword(state, keyword) {
      state.qnaKeyword = keyword;
    },
    setWrongAnswerCount(state, count) {
      state.wrongAnswerCount = count;
    },
    setCourt(state, court) {
      state.court = court;
    },
    setFaqCategory(state, category) {
      state.faqCategory = category;
    },
    setIsThinking(state, status) {
      state.isThinking = status;
    },
    setRequestQna(state, status) {
      state.requestQna = status;
    },
    setQnaPair(state, pair) {
      state.qnaPair = pair;
    },
    closeQnaPair(state) {
      state.qnaPair.show = false;
    },
  },
  actions: {
    requestQnA({ getters, dispatch, commit }, transcript) {
      // Request to QNA
      let data = JSON.stringify({
        "top": 3,
        "question": transcript,
        "includeUnstructuredSources": true,
        "confidenceScoreThreshold": "0.2",
      });
      console.log("KB name and URL", i18n.t("qnaEndpoint"));
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          "https://arsstudio.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=" +
          i18n.t("qnaEndpoint").kbName +
          "&api-version=2021-10-01&deploymentName=production",
        headers: {
          "Ocp-Apim-Subscription-Key": "e4cde2e3cd954d1bb5ad43686d7d6e24",
          "Content-Type": "application/json",
        },
        data: data,
      };
      commit("setIsThinking", true);
      commit("setTextBubbleText", i18n.t("isThinking"));
      axios
        .request(config)
        .then((response) => {
          const qnaAnalytics = {
            timeStamp: new Date(),
            kioskID: getters.getKioskId,
            transcript: transcript,
            result: response.data,
          };
          commit("setTouchRequest", {
            module: "QnA",
            action: transcript,
            response: response.data.answers[0].answer,
            timeStamp: new Date(),
            requestType: "Speech",
          });
          console.log("Result: ", response.data.answers[0]);
          dispatch("addQnaDatatoStrapi", qnaAnalytics);
          if (response.data.answers[0].confidenceScore > 0.6) {
            dispatch("generateAnswer", response.data.answers[0]);
          } else {
            commit("setIsThinking", false);
            var mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            console.log(
              "Map for default location: ",
              getters.getDefaultLocation
            );
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
            });
            dispatch("avatarSpeak", i18n.t("defaultKBError"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    generateAnswer({ dispatch, commit, getters }, result) {
      console.log("Generate Answer: ", result.metadata.intent);
      if (getters.getIsThinking) {
        commit("setQnaStatus", false);
        commit("setIsThinking", false);
        const metadata = result.metadata.intent;
        const ansObj = result;
        commit("setDefaultBubbleText", false);
        commit("closeOptionsViewer");
        switch (metadata) {
          case "navigate":
            var menuName = ansObj.metadata.navigateto;
            router.addRoute({
              path: menuName.toLowerCase(),
              component: () => import("@/views" + menuName),
            });
            if (router.currentRoute.path !== menuName.toLowerCase()) {
              router.push(menuName.toLowerCase()).then(() => {
                commit("setQnaKeyword", "");
                dispatch("avatarSpeak", ansObj.answer);
                let page = i18n
                  .t("homepages")
                  .filter((page) => page.pageType[0].pathName === menuName);
                commit("setPageHeader", page[0].pageType[0].pageHeader);
              });
            } else {
              commit("setQnaKeyword", "");
              dispatch("avatarSpeak", ansObj.answer);
            }
            break;

          case "showmap":
            try {
              var mapPath = i18n
                .t("maps")
                .filter(
                  (map) => map.mapName.toLowerCase() === ansObj.metadata.mappath
                );
              //var mapPath = getters.getMaps.get(ansObj.metadata.mappath)
              console.log("Map Path", mapPath);
              dispatch("openImageViewer", {
                name: mapPath[0].mapName,
                url: mapPath[0].mapImage,
              });
              if (ansObj.answer !== "default") {
                dispatch(
                  "avatarSpeak",
                  ansObj.answer + " " + mapPath[0].speech
                );
              } else {
                dispatch("avatarSpeak", mapPath[0].speech);
              }
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarSpeak", i18n.t("mapNotFound"));
            }

            break;

          case "speak":
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showform":
            try {
              var formName = ansObj.metadata.formname;
              getters.getForms.forEach((form) => {
                if (form.formName.toLowerCase() === formName.value) {
                  console.log("Form Information", form);
                  if (form.documentType !== "eforms") {
                    dispatch("openPdfViewer", {
                      url: form.document,
                      name: form.formName,
                      desc: "Hello There",
                      emailLink: form.documentUrl,
                      type: form.documentType,
                    });
                  } else {
                    dispatch("openEmailViewer", {
                      pdfType: form.documentType,
                      pdfName: form.formName,
                      pdfUrl: form.documentUrl,
                    });
                  }

                  dispatch("avatarSpeak", ansObj.answer);
                }
              });
            } catch (e) {
              console.log(e.message);
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarSpeak", i18n.t("formNotFound"));
            }

            break;
          case "searchbykeyword":
            var linkid = ansObj.metadata.linkid;
            // Give a pop with question and answer?
            console.log("Link id : ", linkid);
            console.log("Faqs List: ", i18n.t("faqs"));
            var matchingFaq = i18n
              .t("faqs")
              .filter((doc) => doc.linkId.toString() === linkid);
            console.log("Matching Faq: ", matchingFaq);
            commit("setQnaPair", {
              show: true,
              question: matchingFaq[0].question,
              answer: matchingFaq[0].answer,
            });
            dispatch("avatarSpeak", ansObj.answer);
            break;
          case "showfaqcategory":
            commit("setQnaKeyword", "");
            var faqKeyword = ansObj.metadata.keyword;

            router.addRoute({
              path: "/faqs",
              component: () => import("@/views/faqs"),
            });
            if (router.currentRoute.path !== "/faqs") {
              router.push("/faqs");
            }
            commit("setPageHeader", "Frequently Asked Questions");

            var count = i18n
              .t("faqCategory")
              .filter((cat) => cat.categoryName.toLowerCase() === faqKeyword);
            if (count.length === 0) {
              var englishFaqList = getters.getFaqs.filter(
                (faq) => faq.locale === "en"
              );
              var linkId = englishFaqList.filter((faq) =>
                faq.question.toLowerCase().includes(faqKeyword)
              );
              commit("setQnaKeyword", linkId[0].linkId);
            } else {
              commit("setSelectedFaqCat", {
                name: faqKeyword,
                type: "expansionPanel",
              });
            }
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showformcategory":
            var formKeyword = ansObj.metadata.keyword;
            var court = ansObj.metadata.court;
            router.addRoute({
              path: "/forms",
              component: () => import("@/views/forms"),
            });
            if (router.currentRoute.path !== "/forms") {
              router.push("/forms");
            }
            commit("setPageHeader", "Forms");
            commit("setQnaKeyword", formKeyword);
            commit("setCourt", court);
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showformpackets":
            var packetInfo = getters.getFormPacketsEnglish.get(
              ansObj.metadata.keyword
            );
            dispatch("openPdfViewer", {
              url: packetInfo.packetFiles,
              name: packetInfo.packetName,
              desc: packetInfo.packetDesc,
              emailLink: packetInfo.packetLink,
              type: "Packet",
            });
            //dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showhours":
            dispatch("openCourtTimingViewer", {});
            dispatch("avatarSpeak", ansObj.answer);

            break;

          case "session":
            var action = ansObj.metadata.find(
              ({ name }) => name === "action"
            ).value;
            if (action === "still there") {
              dispatch("kioskStillThere");
            } else {
              dispatch("resetKiosk");
            }
            break;

          case "followup":
            console.log("Answer object at followup: ", ansObj);
            var prompts = ansObj.dialog.prompts;
            var followUp = [];
            console.log("How followup works: ", ansObj.answer, prompts);
            dispatch("avatarSpeak", ansObj.answer);
            prompts.forEach((item) => followUp.push(item));
            dispatch("openOptionsViewer", {
              dialogHeader: i18n.t("selectOptionMsg"),
              dialogData: followUp,
            });
            break;
          default:
            mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
            });
            dispatch("avatarSpeak", i18n.t("kbError"));
            commit("setIsThinking", false);
            break;
        }
      }
    },
  },
};
