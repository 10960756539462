<template>
  <v-row no-gutters>
    <v-col cols="6">
      <avatar :initialWidth="avatarWidth"></avatar>
    </v-col>
    <v-col cols="6" align-self="center">
      <v-row align="center" justify="center">
        <v-col align="center" cols="12">
          <span class="text-h3">
            {{ statusHeader }}
          </span>
        </v-col>
        <v-col cols="8" align="center">
          <v-progress-linear color="success" :indeterminate="intermediate" value="100" rounded class="mb-2" height="15">
          </v-progress-linear>
          <span class="text-h5">
            {{ status }}
          </span>
        </v-col>
        <!-- <v-col cols="12" align="center">
          <v-btn @click="updateText()" color="success" large v-if="getDataLoaded">Continue</v-btn>
        </v-col> -->
      </v-row>
    </v-col>
    <v-col cols="12" align="end" class="px-15">
      <span class="px-5 text-h6">
        {{ location }}
      </span>
      <v-icon @click="$router.push('/rec')" class="mr-10" size="50" color="grey lighten-1">
        mdi-cog-outline
      </v-icon>

      <v-icon @click="$store.dispatch('logout')" size="50" color="error lighten-2">
        mdi-power-standby
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar.vue";

import { mapGetters } from "vuex";

export default {
  name: "loadData",
  data() {
    return {
      statusHeader: "Fetching My Intelligence!",
      status: "Initiating..",
      intermediate: true,
      value: 0,
      location: "",
    };
  },
  components: {
    avatar,
  },
  computed: {
    ...mapGetters([
      "appDimensions",
      "getKioskProfile",
      "getAvatarLoaded",
      "getDataLoaded",
      "hasCamera",
      "hasMicrophone",
    ]),
    avatarWidth() {
      return this.appDimensions.width / 2.1;
    },
  },
  watch: {
    getAvatarLoaded(newVal) {
      if (newVal) {
        if (!this.getDataLoaded) {
          this.initLoadData();
          this.location = this.getKioskProfile.data().name;
        }
        // console.log('Camera and Microphone', this.hasCamera, this.hasMicrophone)
        //  if (this.hasCamera && this.hasMicrophone) {
        //    console.log("Camera and Microphone accessed");
        //    this.status = "Camera and Microphone Accessed"
        //    this.initLoadData();
        //  } else if(this.hasMicrophone && !this.hasCamera) {
        //      console.log("Unable to access Camera");
        //  } else if(!this.hasMicrophone && this.hasCamera){
        //    console.log("Unable to access Microphone");
        //  }else{
        //    console.log("Unable to access Camera and Microphone");
        //  }
      }
    },
  },
  methods: {
    initLoadData() {
      const URI = this.getKioskProfile.data().cmsLink;
      console.log("URI :", this.getKioskProfile.data());
      this.$store
        .dispatch("createApolloConnection", URI + "/graphql")
        .then((response) => {
          console.log('Response: ', response)
          this.status = response;
          this.$store.dispatch("fetchFloors").then((response) => {
            console.log('Response: ', response)
            this.status = response;
            this.$store.dispatch("fetchMaps").then((response) => {
              this.status = response;
              this.$store.dispatch("fetchFaqs").then((response) => {
                this.status = response;
                this.$store.dispatch("fetchFaqCategory").then((response) => {
                  this.status = response;
                  this.$store.dispatch("fetchFormCategory").then(response => {
                    this.status = response;
                    this.$store.dispatch("fetchForms").then(response => {
                      this.status = response
                      this.$store.dispatch("fetchHomePage").then((response) => {
                        console.log('Response: ', response)
                        this.status = response;
                        this.$store.commit("setDataLoaded", true);
                        this.$store.commit("setDefaultLocation", this.getKioskProfile.data().defaultLocation)
                        this.status = "Data Loaded";
                        this.$store.commit("setEnableAllRecognition", true);
                        this.status = "Enabled All Recognition";
                        this.$store.commit("setEnableSpeechRecognition", true);
                        this.status = "Enabled Speech Recogniton";
                        this.$store.commit("setEnableFaceDetection", true);
                        this.status = "Enabled Face Detection";
                        this.intermediate = false;
                        this.status = "Fetch Complete!";
                        this.statusHeader = "Completed!";
                        setTimeout(() => {
                          //this.$store.dispatch("setDefaults")
                          this.updateText();
                        }, 2000)
                      });
                    })
                  });
                });
              });
            });
          });
        });
    },

    updateText() {
      this.$router.push("/");
      this.$store.commit("setDefaultBubbleText", true);
      this.$store.commit(
        "setTextBubbleText",
        "Hi, my name is " + this.getKioskProfile.data().avatarName + ", I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’"
      );
    },
  },
};
</script>
