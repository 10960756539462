import {
    floorQuery,
    mapQuery,
    faqQuery,
    faqQuery2,
    faqCategoryQuery,
    homepageQuery,
    formCategoryQuery,
    formQuery,
    formQuery2
} from '@/graphql/query';
import { ApolloClient } from 'apollo-client';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        dataLoaded: false,
        apolloClient: undefined,
        modules: [],
        languages: [{
            name: "English",
            code: "en",
            load: true
        }],
        homepages: [],
        floors: [],
        maps: new Map(),
        kioskLocation: '',
        defaultLocation: '',
        faqs: [],
        faqCategory: [],
        formCategory: [],
        forms: []
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        getApolloClient: state => state.apolloClient,
        getModules: state => state.modules,
        getLanguages: state => state.languages,
        getHomepage: state => state.homepages,
        getFloors: state => state.floors,
        getMaps: state => state.maps,
        getFaqs: state => state.faqs,
        getFaqCategories: state => state.faqCategory,
        getFormCategories: state => state.formCategory,
        getForms: state => state.forms,
        getKioskLocation: state => state.kioskLocation,
        getDefaultLocation: state => state.defaultLocation
    },
    mutations: {
        setDataLoaded(state, status) {
            state.dataLoaded = status;
        },
        setApolloClient(state, uri) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            });
            state.apolloClient = new ApolloClient({
                link: new HttpLink({ uri: uri }),
                cache: new InMemoryCache({ fragmentMatcher })
            })
        },
        setModules(state, module) {
            state.modules.push(module)
        },
        emptyModules(state) {
            state.modules = []
        },
        setLanguages(state, lang) {
            state.languages.push(lang)
        },
        emptyLanguages(state) {
            state.languages = []
        },
        setkioskLocation(state, location) {
            state.kioskLocation = location
        },
        setDefaultLocation(state, location) {
            state.defaultLocation = location
        },
        setHomepage(state, page) {
            state.homepages.push(page)
        },
        setFloors(state, floor) {
            state.floors.push(floor)
        },
        setMaps(state, map) {
            state.maps.set(map.mapName.toLowerCase().trim(), map)
        },
        setFaqs(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category,
                    showMap: faq.showMap,
                    mapName: faq.mapName,
                    showForm: faq.showform,
                    formName: faq.formName,
                    showQrCode: faq.showQrcode,
                    qrInfo: faq.qrDetails,
                    locale: faq.locale,
                    keyword: faq.keyword,
                    linkId: faq.LinkID
                }
                state.faqs.push(data)
            })
        },
        setFaqCategory(state, faqCat) {
            state.faqCategory.push(faqCat)
        },
        setFormCategory(state, formCat) {
            state.formCategory.push(formCat)
        },
        setForms(state, form) {
            state.forms.push(form)
        }
    },
    actions: {
        createApolloConnection({ commit }, uri) {
            return new Promise((resolve, reject) => {
                if (uri === '' || uri === null) reject('Empty or Invalid URI')
                try {
                    commit('setApolloClient', uri)
                    resolve('Apollo linked successfully');
                } catch (err) {
                    reject(err.message);
                }
            })
        },
        initiateDataPull({ commit, dispatch }, kioskData) {
            console.log('Kiosk Information: ', kioskData.data())
            commit('setQnaEndpointEN', kioskData.data().englishQna)
            commit('setQnaEndpointES', kioskData.data().spanishQna)
            commit('setQnaEndpointMD', kioskData.data().mandarinQna)
            commit('setQnaEndpointPB', kioskData.data().punjabiQna)
            commit('setQnaEndpointAS', kioskData.data().assyrianQna)
            commit('setkioskLocation', kioskData.data().locationCode)
            commit('setAvatarDetails', kioskData.data().avatarInfo)
            dispatch('indentifyModuleLang', {
                module: kioskData.data().modules,
                language: kioskData.data().languages
            })
            dispatch('identifyAvatarLangCodes', "en")
            //dispatch('updateAutoReferesh', kioskData)
        },
        indentifyModuleLang({ commit, dispatch }, data) {
            commit('emptyModules')
            commit('emptyLanguages')
            console.log('At module language: ', data)
            data.module.forEach(mod => {
                commit('setModules', mod)
            })
            data.language.forEach(lang => {
                commit('setLanguages', lang)
            })
            dispatch('loadAvatar')
        },
        fetchHomePage({ commit, getters, dispatch }) {
            console.log('Modules to be loaded: ', getters.getModules)
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: homepageQuery,
                        variables: {
                            "alias": getters.getModules,
                            "lang": lang,
                            "location": getters.getKioskLocation
                        }
                    }).then(items => {
                        items.data.homepages.forEach(page => {
                            dispatch('saveInCache', page.displayIcon.url).then(response => {
                                page.displayIcon.url = response
                                commit('setHomepage', page);
                            })
                        })
                    })
                    resolve('Homepage fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })

            })
        },

        // Fetch Floor from strapi
        fetchFloors({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: floorQuery,
                        variables: {
                            "location": getters.getKioskLocation,
                            "lang": lang
                        }
                    }).then(floors => {
                        floors.data.floors.forEach(floor => {
                            dispatch('saveInCache', floor.floorImage.url)
                                .then(response => {
                                    floor.floorImage.url = response;
                                    commit('setFloors', floor)
                                })
                        })

                    })
                    resolve('Floors fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })
            })

        },

        // Fetching Maps from Strapi
        fetchMaps({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: mapQuery,
                        variables: {
                            "location": getters.getKioskLocation,
                            "lang": lang
                        }
                    }).then(maps => {
                        maps.data.maps.forEach(map => {
                            dispatch('saveInCache', map.mapImage.url)
                                .then(response => {
                                    commit('setMaps', {
                                        mapName: map.mapName.trim(),
                                        mapFloor: map.mapFloor,
                                        mapImage: response,
                                        speech: map.speech,
                                        category: map.category,
                                        displayPosition: map.displayPosition,
                                        locale: map.locale
                                    })
                                })
                        })
                    })
                    resolve('Maps fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })
            })

        },

        fetchFaqs({ commit, getters }) {

            // More than 100 entries, need 2 queries
            // Graphql allows only 100 pulls
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: faqQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(faqs => {
                        commit('setFaqs', faqs.data.faqs);
                    })

                    getters.getApolloClient.query({
                        query: faqQuery2,
                        variables: {
                            "lang": lang
                        }
                    }).then(faqs => {
                        commit('setFaqs', faqs.data.faqs);
                    })
                    resolve('Faqs fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })
            })


        },

        fetchFaqCategory({ commit, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: faqCategoryQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(faqCategory => {
                        faqCategory.data.faqCategories.forEach(faqCat => {
                            commit('setFaqCategory', faqCat)
                        })
                        //

                    })
                    resolve('Faq Categories fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })
            })
        },
        fetchFormCategory({ commit, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: formCategoryQuery,
                        variables: {
                            "lang": lang
                        }
                    }).then(formCategory => {
                        formCategory.data.formCategories.forEach(formCat => {
                            commit('setFormCategory', formCat)
                        })

                    })
                    resolve('Form Categories fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })
            })

        },

        fetchForms({ commit, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    getters.getApolloClient.query({
                        query: formQuery
                    }).then(forms => {
                        forms.data.forms.forEach(form => {
                            if (form.documentType !== 'eforms') {
                                dispatch('saveInCache', form.document.url)
                                    .then(response => {
                                        commit('setForms', {
                                            formName: form.formName,
                                            formCategory: form.formCategory,
                                            documentType: form.documentType,
                                            document: response,
                                            documentUrl: form.documentUrl,
                                            locale: form.locale
                                        })
                                    })
                            } else {
                                commit('setForms', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    documentType: form.documentType,
                                    document: null,
                                    documentUrl: form.documentUrl,
                                    locale: form.locale
                                })
                            }
                        })
                    })

                    getters.getApolloClient.query({
                        query: formQuery2
                    }).then(forms => {
                        forms.data.forms.forEach(form => {
                            if (form.documentType !== 'eforms') {
                                dispatch('saveInCache', form.document.url)
                                    .then(response => {
                                        commit('setForms', {
                                            formName: form.formName,
                                            formCategory: form.formCategory,
                                            documentType: form.documentType,
                                            document: response,
                                            documentUrl: form.documentUrl,
                                            locale: form.locale
                                        })
                                    })
                            } else {
                                commit('setForms', {
                                    formName: form.formName,
                                    formCategory: form.formCategory,
                                    documentType: form.documentType,
                                    document: null,
                                    documentUrl: form.documentUrl,
                                    locale: form.locale
                                })
                            }
                        })
                    })
                    resolve('Form fetched successfully for language: ' + lang.name)
                },
                    error => {
                        reject(error.message)
                    })
            })
        },
        saveInCache({ state, getters }, path) {
            return new Promise((response, reject) => {
                state.fileCache = caches.open('fileCache')
                    .then(cache => {
                        cache.match(getters.getCMSlink + path)
                            .then(cacheResponse => {
                                if (cacheResponse) {
                                    return (cacheResponse.blob())
                                        .then(blob => {
                                            response(URL.createObjectURL(blob))
                                        })
                                }
                                else {
                                    cache.add(getters.getCMSlink + path)
                                    cache.match(getters.getCMSlink + path)
                                        .then(cacheResponse => {
                                            return (cacheResponse.blob())
                                                .then(blob => {
                                                    response(URL.createObjectURL(blob))
                                                })
                                        })
                                }
                            })
                    })
            })
        }
    }
}
