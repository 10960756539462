<template>
  <v-row no-gutters>
    <v-col class="mr-5" v-if="showAvatar" cols="1">
      <avatar :initialWidth="190"></avatar>
    </v-col>
    <v-col>
      <v-card class="bubbleBorder px-5" height="180" color="rgba(30, 101, 39, 0.88)">
        <v-row no-gutters class="fill-height ma-0 pa-0">
          <v-col class="px-5" align="center" align-self="center">
            <span :class="{ 'text-h4': getTextBubbleText.length >= 200 }"
              class="white--text text-h3 font-weight-medium pl-5">
              {{ getTextBubbleText }}
            </span>
          </v-col>
          <v-col cols="1" align="center" align-self="center" v-if="$t('qnaEndpoint').enable">
            <v-row no-gutters v-if="getNetworkConnection" justify="center">
              <v-col v-if="!getIsThinking">
                <v-img v-if="speakingStatus === 'Tap To Talk'" src="@/assets/talkButton.png" contain
                  class="button-pulse" @click="actionOnTap()">
                </v-img>
                <v-img v-else class="talk-button" @click="actionOnTap()" contain width="140px"
                  :src="require('../assets/' + talkButtonSrc)">
                </v-img>
              </v-col>
              <v-col v-else>
                <v-img contain width="140px" :src="require('../assets/' + talkButtonSrc)">
                </v-img>
              </v-col>
              <span class="white--text">
                {{ speakingStatus }}
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col v-if="showAvatar" class="d-flex my-3" align="center" justify="center" cols="12">
      <v-icon size="80" class="ml-10" @click="$router.push('/');
      backHome();
      " color="primary">
        mdi-arrow-left-circle
      </v-icon>
      <v-spacer></v-spacer>
      <span class="text-h3 ml-n10 font-weight-bold my-auto">
        {{ pageHeader }}
      </span>
      <v-spacer></v-spacer>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar";
import { mapGetters } from "vuex";

export default {
  name: "appHeader",
  components: {
    avatar,
  },
  data() {
    return {
      showAvatar: false,
      pageHeader: "",
      changeFont: false,
      speakingStatus: "Tap To Talk",
      talkButtonSrc: "talkButton.png",
    };
  },
  computed: {
    ...mapGetters([
      "getTextBubbleText",
      "getPageHeader",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getTouchCount",
      "isUserLooking",
      "getCurrentLanguage",
      "getNetworkConnection",
      "getIsThinking"
    ]),
    letterWiseText() {
      let temp = this.getTextBubbleText.split(" ")
      console.log(temp)
      return 1
    }
  },
  watch: {
    $route(to, from) {
      console.log("ROUTE TO", to.path);
      console.log("ROUTE FROM", from.path);
      if (to.path !== "/") {
        this.showAvatar = true;
      } else {
        this.showAvatar = false;
      }
    },
    getPageHeader(newVal) {
      this.pageHeader = newVal;
    },
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
        this.speakingStatus = this.$i18n.t("isSpeaking");
        this.$store.commit("setSpeechRecognitionHandler", false);
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = this.$i18n.t("notSpeaking");
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.speakingStatus = this.$i18n.t("isListening");
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = this.$i18n.t("notSpeaking");
      }
    },
    getQnaStatus(newVal) {
      if (newVal) {
        this.speakingStatus = "Thinking";
        this.talkButtonSrc = "talkButton.png";
      } else {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = this.$i18n.t("notSpeaking");
      }
    },
    getCurrentLanguage(newVal) {
      this.speakingStatus = this.$i18n.t("notSpeaking");
    }
  },
  methods: {
    actionOnTap() {
      if (this.getIsSpeaking) {
        window.stopSpeech();
        this.$store.commit("setTouchRequest", {
          module: "Default",
          action: "Touch Detected",
          response: "Stop Speech Initiated",
          timeStamp: new Date(),
          requestType: "Touch",
        });
      } else {
        this.$store.commit("setSpeechRecognitionHandler", true);
        this.$store.commit("setTouchCount", this.getTouchCount + 1);
        //if (this.$i18n.t("qnaEndpoint").enable) {
        // To start session using touch - when no face detected
        if (!this.isUserLooking) {
          console.log("User Session started");
          if (this.getTouchCount === 1) {
            // start time
            this.$store.commit("setStartUserSession", new Date());

            //session start object
            this.$store.commit("setTouchRequest", {
              module: "Default",
              action: "Touch Detected",
              response: "User Session Started - Touch Detected",
              timeStamp: new Date(),
              requestType: "Session",
            });
          }
        }
        // } else {
        //   if (!this.getIsSpeaking) {
        //     this.$store.dispatch("avatarSpeak", this.$i18n.t("blockedSpeaking"));
        //   } else {
        //     window.stopSpeech();
        //   }
        // }
      }
    },
    backHome() {
      window.stopSpeech();
      // Touch Usage
      //this.$store.commit('setTextBubbleText', 'Tap the microphone button, to ask me a question.');
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      this.$store.commit(
        "setTextBubbleText",
        this.$i18n.t("bubbletextafteraction")
      );
      this.$store.commit("setTouchRequest", {
        module: "App Header",
        action: "navigate",
        response: "Home Page",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
};
</script>

<style scoped>
.bubbleBorder {
  border-radius: 0 0 30px 30px !important;
}

.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}

.button-pulse {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
  display: inline-block;
  width: 135px;
  height: 135px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>

<i18n>
{
  "en": {
      "bubbletextafteraction": "Tap the microphone button, to ask me a question.",
      "isSpeaking": "Speaking",
      "notSpeaking": "Tap To Talk",
      "isListening": "Listening" 
    },
  "es":{
      "bubbletextafteraction": "Toca el botón del micrófono, para preguntarme algo.",
      "isSpeaking": "Discurso",
      "notSpeaking": "Toca para hablar",
      "isListening": "Escuchando"
    },
  "md" :{
      "bubbletextafteraction": "点击麦克风按钮，问我问题。",
      "isSpeaking": "请讲", 
      "notSpeaking": "点击说话",
      "isListening": "听力"
    },
  "pb" :{
      "bubbletextafteraction": "ਪੰਜਾਬੀ ਭਾਸ਼ਾ ਲਈ ਅਵਾਜ਼ ਪਛਾਣ ਅਯੋਗ ਹੈ।",
      "isSpeaking": "ਬੋਲ ਰਿਹਾ ਹਾਂ",
      "notSpeaking": "ਅਯੋਗ"
    },
  "as" :{
      "bubbletextafteraction": "ܒܪܢܝܐܝܬܝܐ ܕܦܘܠܝܕܐ ܕܡܘܬܒܐ ܢܣܛܐ ܕܐܬܘܪܬܝܐ ܐܠܗܐܢܐ ܐܬܪܐ.",
      "isSpeaking": "ܠܘܠܐ",
      "notSpeaking": "ܕܘܠܐܐ"
    } 
}
</i18n>
